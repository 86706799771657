import { Question, Form } from './Types';
class MockData {
  public static Questions:Question[] = [
    {
      QuestionText:"The IT teams are stressed, many have not had a weekend off in month's. How can we provide better work life balance?",
      CurrentVote:41,
      Form:"IT Town Hall"
    },
    {
      QuestionText:"The Orlando GSC building lease will expire in a few years. What are the plans for the associates on this location once the lease expired?",
      CurrentVote:31,
      Form:"IT Town Hall"
    },
    {
      QuestionText:"We seem to have had several products selected by the business without IT engagement. Does the Business seem to have learned the lesson of engagement after cornerstone?",
      CurrentVote:24,
      Form:"IT Town Hall"
    },
    {
      QuestionText:"Do you intend to re-align our IT teams around products and services rather than traditional groups based on role?",
      CurrentVote:19,
      Form:"IT Town Hall"
    },
  ]
  public static Forms:Form[] = [
    {
      FormName:"IT Town Hall",
      ExpirationDate:new Date("7/19/2019"),
      Author:"Lyle Cheatham",
      id:"1",
      FormGuid:"aasdfsd"
    },
    {
      FormName:"Quarterly Meeting",
      ExpirationDate:new Date("7/29/2019"),
      Author:"Lyle Cheatham",
      id:"2",
      FormGuid:"aasdfsd"
    },
    {
      FormName:"Yearly Meeting",
      ExpirationDate:new Date("8/10/2019"),
      Author:"Bob Cheatham",
      id:"3",
      FormGuid:"aasdfsd"
    },
    {
      FormName:"IT Meeting",
      ExpirationDate:new Date("8/10/2020"),
      Author:"Bob Cheatham",
      id:"4",
      FormGuid:"aasdfsd"
    },
  ]
} 
export default MockData;