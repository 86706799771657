import { Question, Form, GraphResponse } from './Types';
import MockData from './MockData';
import axios from 'axios';


export class MockService {
  public static GetQuestions = (): Question[] => {
    return MockData.Questions;
  }
  public static IncrementQuestion = (Question: Question, Questions: Question[]): Question[] => {
    let selectedIndex = Questions.findIndex((question) => { return question.QuestionText == Question.QuestionText });
    Questions[selectedIndex].CurrentVote++;
    return Questions;
  }
  public static DecrementQuestion = (Question: Question, Questions: Question[]): Question[] => {
    let selectedIndex = Questions.findIndex((question) => { return question.QuestionText == Question.QuestionText });
    Questions[selectedIndex].CurrentVote--;
    return Questions;
  }

  public static AddQuestion = (Question: Question, Questions: Question[]): Question[] => {
    return [Question, ...Questions];
  }

  public static GetForms = (): Form[] => {
    return MockData.Forms;
  }
}
export class RealService {
  //public static BaseApiUrl:string = "http://localhost:7071/api/";
  public static BaseApiUrl: string = "https://hdsfn.azurewebsites.net/api/";

  public static GetQuestions = async (formId: string): Promise<Question[]> => {
    let code = "?code=0UvVSA5iFkYHuy3L1LWI53JAfEw3eo0Gqzcxi4SM32ITGFvLs/2q9w==";
    let endpoint: string = "GetVotes";
    let res = await axios.get(RealService.BaseApiUrl + endpoint + code + "&formId=" + formId);
    let { value } = res.data;

    let questions: Question[] = [];
    value.forEach((item: GraphResponse) => {
      let fields: Question = item.fields;
      questions.push(fields);
    });
    return questions;
  }
  public static IncreaseVote = async (Question: Question, Questions: Question[], captcha: string): Promise<{ questions: Question[], success: Boolean }> => {
    let code = "&code=aC9RwtGfry/ox87OeFJ0XDPldC60GrpNcZI0X0w8KNNBV9t6pzbVnw==";
    let endpoint: string = "IncreaseVote";
    let res = await axios.get(RealService.BaseApiUrl + endpoint + "?itemId=" + Question.id + code + "&captcha=" + captcha);
    let selectedIndex = Questions.findIndex((question) => { return question.QuestionText == Question.QuestionText });
    if (res.data === "captcha_failure") return { questions: Questions, success: false }
    else {
      Questions[selectedIndex].CurrentVote++;
      return { questions: Questions, success: true };
    }
  }
  public static DecreaseVote = async (Question: Question, Questions: Question[], captcha: string): Promise<{ questions: Question[], success: Boolean }> => {
    let code = "&code=4jx6FX3lpG0197GuVJHahU6zLzV/pEyioOL8pWL19tNUa/LC90nPtg==";
    let endpoint: string = "DecreaseVote";
    let res = await axios.get(RealService.BaseApiUrl + endpoint + "?itemId=" + Question.id + code + "&captcha=" + captcha);
    let selectedIndex = Questions.findIndex((question) => { return question.QuestionText == Question.QuestionText });
    if (res.data === "captcha_failure") return { questions: Questions, success: false }
    else {
      Questions[selectedIndex].CurrentVote--;
      return { questions: Questions, success: true };
    }
  }

  public static AddQuestion = async (Question: Question, Questions: Question[], captcha: string): Promise<{ questions: Question[], success: Boolean }> => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    let code = "?code=JqFLjXm1MAZ8AoRa4q0nij32TPOOJlupIntRcY2pbIEsaUPeZumiuw==";
    let endpoint: string = "AddQuestion";
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let res = await axios.post(RealService.BaseApiUrl + endpoint + code, { "QuestionText": Question.QuestionText, "Form": Question.Form, "FormId": Question.FormId, "Captcha": captcha },axiosConfig);
    let { id } = res.data;
    if (res.data === "captcha_failure") return { questions: Questions, success: false }
    Question.id = id;
    return { questions: [Question, ...Questions], success: true };
  }

  public static GetForms = async (): Promise<Form[]> => {
    let code = "?code=/BHNlDWANXDzmfNg4WZp1jkfvDQrZiXHyEt7TcFhxAOlNSUqySkTiw==";
    let endpoint: string = "GetQnAs";
    let res = await axios.get(RealService.BaseApiUrl + endpoint + code);
    let { value } = res.data;

    let questions: Form[] = [];
    value.forEach((item: GraphResponse) => {
      let fields: Form = item.fields;
      questions.push(fields);
    });
    return questions;
  }
}
export default MockService;