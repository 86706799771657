/* Main Imports */
import "babel-polyfill";
import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from './withRoot';

/* Material Core Imports */
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import ReCAPTCHA from "react-google-recaptcha";
import {isIE} from 'react-device-detect';

import moment from 'moment';
import { cyan, deepOrange, grey, red, blue, green, pink, amber, indigo, lightBlue, blueGrey } from '@material-ui/core/colors';

/* Material Icon Imports */
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/DoneRounded';
import Moment from 'react-moment';
import FlipMove from 'react-flip-move';
import Container from '@material-ui/core/Container';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

import { MockService, RealService } from './MockService';
import { Question, Form } from './Types';
import VoteItem from './VoteItem';
import logo from './logo.svg';
import './App.css';
import { SnackbarContent } from '@material-ui/core';
require('es6-promise/auto');
const colors = { deepOrange, grey, red, blue, green, pink, amber, indigo, lightBlue, blueGrey, cyan };
const recaptchaRef: any = React.createRef();
const recaptchaRefInvis: any = React.createRef();
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: grey[900],
    },
    appBarBehind: {
      zIndex: theme.zIndex.drawer ,
      backgroundColor: grey[50],
      top:64,
      '@media (max-width: 800px)': {
        top: 58
      },
      '@media (max-width: 600px)': {
        top: 53
      }
    },
    appBarSecondary: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: amber[500],
      top: 64,
      width:"100%",
      paddingTop: 6
    },
    logo: {
      marginTop: -10,
      height:40,
      width:240,
      paddingRight: 20
    },
    headerText: {
      flexGrow: 1,
      fontWeight: 800
    },
    toolbar: theme.mixins.toolbar,
    toolbarLarger: {
      minHeight: 80
    },
    fab: {
      margin: theme.spacing(1),
      position: 'relative',
      transform: 'rotate(90deg)'
    },
    TabRoot: {
      backgroundColor: amber[600],
    },
    TabIndicator: {
      backgroundColor: grey[900],
    },
    padding: {
      padding: theme.spacing(0, 2),
    },
    badge: {
      backgroundColor: grey[900],
      color: grey[100]
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    expansionPanel: {
      borderRadius: 60,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: "20px !important",
      marginRight: "20px !important",
      '&:before': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    info: {
      backgroundColor: grey[900],
    },
    success: {
      backgroundColor: green[600],
    },
    noaction: {
      backgroundColor: grey[600],
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    staticTool: {
      minHeight: 64,
      '@media (min-width: 600px)': {
      }
    },
    secondaryBarText: {
      '@media (max-width: 800px)': {
        fontSize: 22
      },
      '@media (max-width: 600px)': {
        fontSize: 18
      }
    },
    secondaryBarText2: {
      '@media (max-width: 800px)': {
        fontSize: 18
      },
      '@media (max-width: 600px)': {
        fontSize: 14
      }
    },
    secondaryBarFiller: {
      minHeight: 128,
      '@media (max-width: 800px)': {
        minHeight: 106
      }
    },
    textBox: {
      width:600
    }
  });
type State = {
  questions: Question[];
  votedQuestions: Question[];
  forms: Form[];
  formId: string;
  formTab: any;
  expanded: boolean;
  hasError: boolean;
  hasLoaded: boolean;
  badPath: boolean;
  expired: boolean;
  readOnly: boolean;
  snackVisible: boolean;
  buttonLoader: boolean;
  questionField: string;
  snackText: string;
  formName: string;
  captcha: string;
  captchaValue: string;
  captchaElement: any;
  currentVoteId: number;
  expirationDate: Date;
};
const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: 0,
    right: 3,
    backgroundColor: grey[900],
    color: grey[100],
    // The border color match the background color.
    border: `1px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
      }`,
  },
}))(Badge);
class App extends React.Component<{
  location: any;
} & WithStyles<typeof styles>, State> {

  constructor(props: any) {
    super(props);
    this.updator = this.updator.bind(this);
    let votedQuestions: Question[] = [];
    let votedQuestionsString: any = localStorage.getItem("votedQuestions");
    if (votedQuestionsString) votedQuestions = JSON.parse(votedQuestionsString);
    this.state = {
      formTab: 0,
      formId: "1",
      votedQuestions: votedQuestions,
      questions: [],
      forms: [],
      captchaValue: "",
      captchaElement: recaptchaRefInvis,
      expanded: false,
      hasError: false,
      expired: false,
      readOnly: false,
      hasLoaded: false,
      badPath: false,
      buttonLoader: false,
      currentVoteId: 0,
      snackVisible: false,
      questionField: "",
      snackText: "",
      formName: "",
      captcha: "",
      expirationDate: new Date()
    };
  }
  private updator = (state: any, fc?: Function): void => {
    if (fc) this.setState(state, () => { fc() });
    else this.setState(state);
  }
  private _submitQuestion = async () => {
    const { formName, questions, formTab, votedQuestions, expanded, questionField, hasError, hasLoaded, formId, badPath, snackVisible, snackText, buttonLoader, captcha } = this.state;

    if (questionField === "") {
      this.setState({ hasError: true });
    }
    else {
      this.setState({ buttonLoader: true });
      let question = { QuestionText: questionField, CurrentVote: 1, Form: formName, FormId: formId };
      let newArray = [question, ...votedQuestions];
      localStorage.setItem("votedQuestions", JSON.stringify(newArray));
      let addedQuestions = await RealService.AddQuestion(question, questions, captcha);
      if (addedQuestions.success)
        this.setState({ captcha: "", hasError: false, expanded: !expanded, questions: addedQuestions.questions, questionField: "", votedQuestions: newArray, buttonLoader: false, snackVisible: true, snackText: "Your question has been added" }, recaptchaRef.current.reset());
      else {
        this.setState({ captcha: "", hasError: false, expanded: !expanded, questions: addedQuestions.questions, questionField: "", votedQuestions: newArray, buttonLoader: false, snackVisible: true, snackText: "Your request was rejected by captcha" }, recaptchaRef.current.reset());
      }
    }
  }

  async componentWillMount() {
    let formId = this.props.location.pathname.replace("/", "")
    let questions = await RealService.GetQuestions(formId);
    let forms = await RealService.GetForms();
    let foundForm: Form = forms.filter((form) => { return form.FormGuid === formId }).length === 1 ? forms.filter((form) => { return form.FormGuid === formId })[0] : { FormName: "This form may have been deleted...", FormGuid: "", ExpirationDate: new Date(), Author: "", id: "" };
    var guidTest = new RegExp("^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$");

    let testResult: Boolean = guidTest.test(formId);
    let badPath = !testResult;
    let expired = moment().isAfter(moment(foundForm.ExpirationDate).add(2, "months"));
    let readOnly = moment().isAfter(moment(foundForm.ExpirationDate));
    this.setState({ expired: expired, readOnly: readOnly, questions: questions, forms: forms, hasLoaded: true, formId: formId, badPath: badPath, formName: foundForm.FormName, expirationDate: foundForm.ExpirationDate });
  }
  render() {
    const { classes, location } = this.props;
    const { currentVoteId, captchaElement, captchaValue, captcha, readOnly, expired, formName, expirationDate, questions, formTab, votedQuestions, expanded, questionField, hasError, hasLoaded, formId, badPath, snackVisible, snackText, buttonLoader } = this.state;
   
    let snackClass = snackText.indexOf("removed") >= 0 ? classes.info : snackText.indexOf("captcha") >= 0 ? classes.noaction : classes.success;
    snackClass = snackText.indexOf("Chrome") >= 0 ?classes.noaction:snackClass;
    return (
      <React.Fragment >
        <AppBar elevation={0} position="fixed" className={classes.appBar}>
          <Toolbar className={classes.staticTool}>
            <img className={classes.logo} src={logo}></img>
            <Typography className={classes.headerText} variant={"h4"} color={"secondary"}>Q&A</Typography>
          </Toolbar>
        </AppBar>
        {!hasLoaded &&
          <React.Fragment>
            <div  style={{minHeight:64}}  />
            <Grid container justify="center" alignItems="center" style={{ height: 200 }} >
              <CircularProgress color={"secondary"} />
              <Typography variant="h4" noWrap style={{ fontWeight: 300 }}>
                &nbsp;Loading...
              </Typography>
            </Grid>
          </React.Fragment>
        }
        {!readOnly &&
          <ReCAPTCHA
            onErrored={() => {
              this.setState({ snackText: "Your captcha check failed", snackVisible: true });
            }}

            onExpired={() => {
              this.setState({ captchaValue: "" })
            }
            }
            onChange={(val) => {
              this.setState({ captchaValue: val ? val : "" });
            }}
            ref={captchaElement}
            size="invisible"
            sitekey="6Leso7IUAAAAAMAZ_SmrEsK6ujTExa6NMBqOqbBY"
          />
        }
        {!expired && !badPath && hasLoaded &&
          <React.Fragment>
            <div className={classes.secondaryBarFiller}/>
            <AppBar elevation={0} position="fixed" className={classes.appBarBehind}>
              <Toolbar disableGutters className={classes.staticTool} >
                <Slide in={true} direction={"down"}>
                  <div className={classes.appBarSecondary}>
                    <Grid container justify="center">
                      <div style={{ textAlign: "center" }}>
                        <Typography className={classes.secondaryBarText} style={{ color: grey[900], fontWeight: 800 }} variant={"h4"} >{formName}</Typography>
                        {!readOnly && <Typography className={classes.secondaryBarText2}  style={{ color: grey[900], fontWeight: 30 }} variant={"h6"} >This Q&A closes <Moment fromNow>{expirationDate.toString()}</Moment> on <Moment format={'MMMM Do'}>{expirationDate.toString()}</Moment></Typography>}
                        {readOnly && <Typography className={classes.secondaryBarText2}  style={{ color: grey[900], fontWeight: 30 }} variant={"h6"} >This Q&A closed <Moment fromNow>{expirationDate.toString()}</Moment> on <Moment format={'MMMM Do'}>{expirationDate.toString()}</Moment></Typography>}
                      </div>
                    </Grid>
                  </div>
                </Slide>
              </Toolbar>
            </AppBar>
            {!readOnly &&
              <React.Fragment>
                <Grid container justify="center" style={{ padding: "28px 20px 0px 20px" }}>
                  <TextField
                  inputProps={{style:{width:600}}}
                    value={questionField}
                    label={"Click here to Ask a Question..."}
                    multiline
                    error={questionField.length > 500}
                    rowsMax="4"
                    rows="4"
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({ questionField: event.target.value, hasError: event.target.value === "" ? true : false });
                    }}
                  /></Grid>
                <Grow in={questionField != "" && questionField.length <= 500}>
                  <Grid container justify="center" style={{
                    paddingTop: 20,
                    height: questionField != "" && questionField.length <= 500 ? "inherit" : "0px",
                    visibility: questionField != "" && questionField.length <= 500 ? "visible" : "hidden"
                  }}>
                    <ReCAPTCHA
                      onExpired={() => { this.setState({ captcha: "" }) }}
                      ref={recaptchaRef}
                      sitekey="6LeinbIUAAAAAI4AmbRtAnlzFBsGKPZPlx_gYTSX"
                      onChange={(val: any) => { this.setState({ captcha: val ? val : "" }) }}
                    />
                  </Grid>
                </Grow>
                <Grid container justify="center">
                  <div className={classes.wrapper}>
                    <Button disabled={buttonLoader || questionField === "" || questionField.length > 500 || captcha === ""} style={{ margin: 10 }} size="large" onClick={this._submitQuestion} color="secondary" variant={"contained"}>
                      {questionField === "" ? "Click above to Enter Your Question" :
                        questionField.length > 500 ? "Questions must be below 500 characters" :
                          captcha === "" ? "Click Captcha Above To Submit" : "Submit Question"}
                    </Button>
                    {buttonLoader && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </Grid>
              </React.Fragment>
            }
            <Container maxWidth="md" >
            <List style={{ paddingTop: 0 }}>
              {questions
                .filter((question) => { return question.FormId === formId })
                .sort((x, y) => {
                  if (x.CurrentVote > y.CurrentVote) return -1;
                  else if (x.CurrentVote < y.CurrentVote) return 1;
                  else return 0;
                }).map((question, key) => {

                  return (
                    <React.Fragment key={"voteItem" + key}><VoteItem currentVoteId={currentVoteId} voteId={key} captchaValue={captchaValue} captchaElement={captchaElement} readOnly={readOnly} formId={formId} question={question} questions={questions} updator={this.updator} votedQuestions={votedQuestions} /></React.Fragment>
                  )
                })}
            </List>
            <div style={{minHeight:50}}></div>
            </Container>
            {questions.filter((question) => { return question.FormId === formId }).length === 0 && <Typography style={{ color: grey[400], padding: 40, fontWeight: 300, textAlign: "center" }} variant={"h4"}>Looks like there aren't any questions yet for this Q&A</Typography>}

          </React.Fragment>
        }
        {!expired && badPath &&
          <React.Fragment>
            <div className={classes.toolbar} />
            <Typography style={{ color: grey[400], padding: 40, fontWeight: 300, textAlign: "center" }} variant={"h4"}>Sorry, I couldn't find a Q&A with that ID</Typography>
          </React.Fragment>
        }
        {expired &&
          <React.Fragment>
            <div className={classes.toolbar} />
            <Typography style={{ color: grey[400], padding: 40, fontWeight: 300, textAlign: "center" }} variant={"h4"}>This Q&A is at least two months old and has expired</Typography>
          </React.Fragment>
        }
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={snackVisible}
          autoHideDuration={4000}
          onClose={() => { this.setState({ snackVisible: false }) }}>
          <SnackbarContent className={snackClass} message={<Typography variant={"h6"} style={{ textAlign: "center", fontWeight: 400 }} id="message-id">{snackText}</Typography>} />
        </Snackbar>
      </React.Fragment>
    );
  }
}
export default withRouter(withRoot(withStyles(styles)(App)));
