/* Main Imports */
import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from './withRoot';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

/* Material Core Imports */
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {isIE} from 'react-device-detect';
import ReCAPTCHA from "react-google-recaptcha";

import { cyan, deepOrange, grey, red, blue, green, pink, amber, indigo, lightBlue, blueGrey } from '@material-ui/core/colors';

/* Material Icon Imports */
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import DoneIcon from '@material-ui/icons/DoneRounded';
import ChatIcon from '@material-ui/icons/ChatRounded';
import FlagIcon from '@material-ui/icons/FlagRounded';
import PersonIcon from '@material-ui/icons/PersonRounded';
import NewReleasesIcon from '@material-ui/icons/NewReleasesSharp';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircleRounded';

import { MockService, RealService } from './MockService';
import { Question, Form } from './Types';
import './App.css';

const colors = { deepOrange, grey, red, blue, green, pink, amber, indigo, lightBlue, blueGrey, cyan };


const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    fabProgress: {
      color: grey[400],
      position: 'absolute',
      top: -4,
      left: -4,
      zIndex: 0,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
  });
type State = {
  isLoading: boolean;
  isChallengeUp: boolean;
  isOpen: boolean;
  isVoted: boolean;
  usedCaptchas: string[];
};
type Props = {
  voteId: number;
  currentVoteId: number;
  formId: string;
  readOnly: boolean;
  question: Question;
  questions: Question[];
  votedQuestions: Question[];
  updator: Function;
  captchaElement: any;
  captchaValue: string;
};
const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: !isIE ? 0:5,
    right: !isIE ? 3:22,
    backgroundColor: grey[900],
    color: grey[100],
    // The border color match the background color.
    border: `1px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
      }`,
  },
}))(Badge);
class Voteitem extends React.Component<Props & WithStyles<typeof styles>, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      isChallengeUp: false,
      isLoading: false,
      isVoted: true,
      isOpen: false,
      usedCaptchas: []
    };
  }
  componentWillReceiveProps(props: Props) {
    if (props.currentVoteId === this.props.voteId) {
      if (props.captchaValue != "" && this.state.usedCaptchas.indexOf(props.captchaValue) == -1) {
        this.setState({ usedCaptchas: [...this.state.usedCaptchas, props.captchaElement] })
        this.onVoteItemClick(props.captchaValue);
      }
    }
  }
  componentWillMount() {
    const { classes, question, votedQuestions, questions, updator, formId } = this.props;
    let alreadyVotedIndex = votedQuestions.findIndex((q) => { return ((q.QuestionText == question.QuestionText) && (q.FormId == formId)) });
    this.setState({ isVoted: alreadyVotedIndex !== -1 });
  }
  public getLocal = (key: string): string => {
    let found = localStorage.getItem(key);
    if (found) return found;
    else return "[]"
  }
  public onVoteItemClick = async (captchaValue: string) => {
    const { classes, question, votedQuestions, questions, updator, formId, readOnly, captchaElement } = this.props;
    const { isLoading } = this.state;
    let isVoted: boolean = votedQuestions.findIndex((q) => { return ((q.QuestionText == question.QuestionText) && (q.FormId == formId)) }) !== -1;
    if (!isVoted) {
      let questionResponse = await RealService.IncreaseVote(question, questions, captchaValue);
      if (questionResponse.success) {
        let newArray = [question, ...JSON.parse(this.getLocal("votedQuestions"))];
        localStorage.setItem("votedQuestions", JSON.stringify([question, ...JSON.parse(this.getLocal("votedQuestions"))]));
        updator({ captchaValue: "", votedQuestions: newArray, questions: questionResponse.questions, snackText: "Your vote has been counted", snackVisible: true }, this.setState({ isLoading: false, isVoted: true }));
      }
      else {
        updator({ captchaValue: "", snackText: "Your request was rejected by captcha", snackVisible: true }, this.setState({ isLoading: false }));
      }
    }
    if (isVoted) {
      let questionResponse = await RealService.DecreaseVote(question, questions, captchaValue);
      if (questionResponse.success) {
        let index = JSON.parse(this.getLocal("votedQuestions")).findIndex((q: Question) => { return ((q.QuestionText == question.QuestionText) && (q.FormId == formId)) });
        votedQuestions.splice(index, 1);
        let arr = JSON.parse(this.getLocal("votedQuestions"));
        arr.splice(index, 1);
        localStorage.setItem("votedQuestions", JSON.stringify(arr));
        updator({ captchaValue: "", votedQuestions: votedQuestions, questions: questionResponse.questions, snackText: "Your vote has been removed", snackVisible: true }, this.setState({ isLoading: false, isVoted: false }));
      }
      else {
        updator({ captchaValue: "", snackText: "Your request was rejected by captcha", snackVisible: true }, this.setState({ isLoading: false }));
      }
    }
    captchaElement.current.reset();
  }
  render() {
    const { voteId, classes, question, votedQuestions, questions, updator, formId, readOnly, captchaElement, captchaValue } = this.props;
    const { isOpen, isChallengeUp, isLoading } = this.state;
    let isVoted: boolean = votedQuestions.findIndex((q) => { return ((q.QuestionText == question.QuestionText) && (q.FormId == formId)) }) !== -1;
    let decidedBackground = "";
    let decidedColor = "";
    if (readOnly && question.Answer) {
      decidedBackground = grey[900];
      decidedColor = amber[600];
    }
    else if (isVoted && !isLoading) {
      decidedBackground =green[500];
      decidedColor = grey[100];
    }
    else {
      decidedBackground = "";
      decidedColor = "";
    }

    return (
      <React.Fragment>
        <Dialog onClose={()=>{this.setState({isOpen:false})}} open={isOpen}>
          <DialogTitle >{"Confirm Choice"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
            {isVoted ? "Would you like to remove your upvote?" : "Would you like to upvote this question?"}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={()=>{this.setState({isOpen:false})}} >Cancel</Button>
            <Button variant={"contained"} onClick={() => {
              this.setState({ isOpen:false,isLoading: true, isChallengeUp: true }, updator({ currentVoteId: voteId }, () => { captchaElement.current.execute() }))
              setTimeout(() => {
                if (this.state.isChallengeUp) {
                  this.setState({ isChallengeUp: false, isLoading: false });
                }
              }, 6000)
            }} color="secondary">
              {isVoted ?"Remove":"Upvote"}
          </Button>
          </DialogActions>
        </Dialog>
    <Grow style={{ transformOrigin: '0 0 0' }} in={true} >
          <ListItem disabled={isLoading || readOnly} button
            onClick={() => {
              this.setState({ isOpen: true });
              /*
              
              this.setState({isLoading:true,isChallengeUp:true},updator({ currentVoteId:voteId},()=>{captchaElement.current.execute()}))
              setTimeout(()=>{ 
                if (this.state.isChallengeUp) {
                  this.setState({isChallengeUp:false,isLoading:false});
                } 
              },6000)
              */
            }}>
            <ListItemIcon>
              <div className={classes.wrapper}>
                <Fab disabled={isLoading} size={"large"} color="secondary" disableRipple style={{ boxShadow: "0px 0px 0px 0px", backgroundColor: decidedBackground, color: decidedColor}}>
                  <StyledBadge badgeContent={question.CurrentVote}>
                    {readOnly && question.Answer ? <FlagIcon style={{ fontSize: 45 }} />  
                    : isVoted ? <DoneIcon style={{ fontSize: 45 }} /> 
                    : <KeyboardArrowUpIcon style={{ fontSize: 45 }} />}
                  </StyledBadge>
                </Fab>
                {isLoading && <CircularProgress size={64} className={classes.fabProgress} />}
              </div>
            </ListItemIcon>

            <ListItemText secondaryTypographyProps={{variant:"h5", color:"textPrimary"}} primary={question.QuestionText} secondary={readOnly && question.Answer ? question.Answer : null} 
            style={{ paddingLeft: 10, wordWrap: "break-word" }} />
          </ListItem>
           </Grow>
      </React.Fragment>
    );
  }
}
export default withRoot(withStyles(styles)(Voteitem));
